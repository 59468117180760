import { React, useState, useEffect } from 'react'
import socketIOClient from "socket.io-client"; import "./luigis.css"
import overlay from './img/overlay.png'
import supporto from './img/supporto.svg'
import moment from "moment"
const ENDPOINT = "https://socket.luigis.io";

console.error = function () { }
console.log = function () { }
console.debug = function () { }

function App() {


  const [orders, setOrders] = useState([])
  const [customization, setCustomization] = useState({
    DM_background_overlay: 1,
    DM_background_color: "#F1535F",
    DM_background_text_color: "#FFFFFF",
    DM_logo: "https://luigis.io/wp-content/uploads/2023/05/logo-neg.png",
    PREPARING_column_text: "IN PREPARAZIONE",
    PREPARING_column_text_color: "#000000",
    PREPARING_order_bg_color: "#ffb703",
    PREPARING_order_color: "#000000",
    DELIVERING_column_text: "IN CONSEGNA",
    DELIVERING_column_text_color: "#000000<",
    DELIVERING_order_bg_color: "#FFFFFF",
    DELIVERING_order_color: "#000000",
    orientation: "landscape"
  })

  const [socketLoading, setSocketLoading] = useState(false)
  const [license, setLicense] = useState(null)
  const [expired, setExpired] = useState(false)
  const [hour, setHour] = useState("12:33")

  const readLicense = async () => {
    setLicense("AAAA-BBBB-CCCC-DDDD")
  }

      useEffect(() => {
          const oneHourInMillis = 60 * 60 * 1000; // 1 ora in millisecondi

          function refreshPageHourly() {
              window.location.reload(); // Ricarica la pagina
          }

          const intervalId = setInterval(refreshPageHourly, oneHourInMillis);
          

          readLicense();
          const socket = socketIOClient(ENDPOINT, {
              timeout: 50000
          });

          const updateOrdersInterval = setInterval(() => {
              socket.emit("update-orders");
          }, 1000);

              if (socket) {
                  socket.emit("auth", {
                      license: license,
                      navigator: window.navigator.userAgent,
                      type: "ELIMINA-CODE",
                      role: "SERVER"
                  })

                  socket.on("datetime", data => {
                      setHour(moment(data.datetime).format("HH:mm"))
                  })
                  socket.on("block-device", data => {
                      setExpired(true)
                  })
                  socket.on("getting-customization", data => {
                      setCustomization(data.customization)
                  })
                  socket.on("getting-orders", data => {
                      setOrders(data.orders);
                      setExpired(false)
                      setSocketLoading(true)
                  });
              }

          socket.on('connect_error', (error) => {
              // Gestisci gli errori di connessione qui, se necessario.
          });

          // Cleanup quando il componente viene smontato
          return () => {
              socket.disconnect();
              clearInterval(updateOrdersInterval); // Cancella l'intervallo
              clearInterval(intervalId);
          }
      }, [license, orders]);

  return (
    
    <><div className="w-screen h-screen m-0 flex flex-row items-center justify-between px-16 py-16" style={{
      backgroundImage: customization.DM_background_overlay === 1 ? `url(${overlay})` : null,
      backgroundColor: customization.DM_background_color
    }}>{socketLoading ? <>
      {/* Contenitore powered by Luigis */}
      <div className="absolute w-screen h-screen flex">
        <div className="justify-center mx-auto">
          <p className="text-sm mt-2 font-medium " style={{ color: customization.DM_background_text_color }}>Servizio offerto da <strong><em>Luigi's, soluzioni tech per la ristorazione</em></strong> visita <strong>www.luigis.io</strong></p>
        </div>
      </div>
      {/* Contenitore logo, giorno e ora */}
      <div className="absolute w-screen h-screen flex opacity-80">
        {socketLoading ? <div className="m-auto items-center justify-center">
          <img src={customization.DM_logo} className="mb-4 -ml-10" alt="logo"/>
          <p className="text-6xl font-bold " style={{ color: customization.DM_background_text_color }}>{(new Date().toLocaleString('it-IT', { weekday: "short", day: "2-digit" })).charAt(0).toUpperCase() + (new Date().toLocaleString('it-IT', { weekday: "short", day: "2-digit" })).slice(1) + " | " + hour }</p>
        </div>
        : <div className="m-auto items-center justify-center">
          <div role="status">
            <svg aria-hidden="true" className="inline w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-yellow-400" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
              <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
            </svg>
              <span className="sr-only">Loading...</span>
          </div>
        </div>}
      </div>

      {/* Colonna sinistra IN PREPARAZIONE*/}
      <div className="h-full w-full m-0 flex flex-col items-center justify-start px-4 opacity-95 z-10">
        {/* Titolo IN PREPARAZIONE */}
        <div className="w-full h-24 rounded-xl drop-shadow-lg flex flex-row justify-center mb-8 items-center" style={{ backgroundColor: customization.PREPARING_order_bg_color}}>
          <p className={`${customization.orientation === "landscape" ? "text-6xl" : "text-5xl"} font-bold`} style={{ color: customization.PREPARING_column_text_color }}>{customization.PREPARING_column_text}</p>
        </div>
        {/* Ordini IN PREPARAZIONE, limit: 5 */}
        {orders.filter(order => order.status === "PREPARING").map((item, index) => {
          return (
            <div key={index} className="w-full h-24 rounded-xl drop-shadow-lg flex flex-row justify-center mb-4 items-center" style={{ backgroundColor: customization.PREPARING_order_bg_color}}>
              <p className="text-6xl font-bold" style={{ color: customization.PREPARING_column_text_color }}>{item.description}</p>
            </div>
          )
        }).sort((a, b) => a.created_at > b.created_at ? 1 : -1).slice(0, 7)}
      </div>
      {/* Colonna sinistra IN CONSEGNA*/}
      <div className="h-full w-full m-0 flex flex-col items-center justify-start px-4 opacity-95 z-10">
        {/* Titolo IN CONSEGNA */}
        <div className="w-full h-24 rounded-xl drop-shadow-lg flex flex-row justify-center mb-8 items-center" style={{ backgroundColor: customization.DELIVERING_order_bg_color}}>
            <p className={`${customization.orientation === "landscape" ? "text-6xl" : "text-5xl"} font-bold`} style={{ color: customization.DELIVERING_column_text_color }}>{customization.DELIVERING_column_text}</p>
        </div>
        {/* Ordini IN CONSEGNA, limit: 7 */}
        {orders.filter(order => order.status === "DELIVERING").map((item, index) => {
          return (
            <div key={index} className="w-full h-24 rounded-xl drop-shadow-lg flex flex-row justify-center mb-4 items-center" style={{ backgroundColor: customization.DELIVERING_order_bg_color}}>
              <p className="text-6xl font-bold" style={{ color: customization.DELIVERING_column_text_color }}>{item.description}</p>
            </div>
          )
        }).sort((a, b) => a.created_at > b.created_at ? 1 : -1).slice(0, 7)}
      </div>
    </> : <>{expired ? <div className="w-screen h-screen m-0 flex flex-row items-center justify-between px-16 py-16" style={{
      backgroundImage: customization.DM_background_overlay === 1 ? `url(${overlay})` : null,
      backgroundColor: customization.DM_background_color
    }}>
        <div className="m-auto items-center flex flex-col justify-center">
          <p className="text-6xl font-bold mb-8" style={{ color: customization.DM_background_text_color }}>Ops, c'è qualche problema sulla licenza.</p>
            <img src={supporto} className="w-96 shadow-xl mb-8" alt="logo" />
          <p className="text-6xl font-bold " style={{ color: customization.DM_background_text_color }}>Scansiona il QRcode per contattare l'assistenza.</p>
        </div>
      
      </div> : <div className="m-auto items-center justify-center">
        <div role="status">
          <svg aria-hidden="true" className="inline w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-yellow-400" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
          </svg>
          <span className="sr-only">Loading...</span>
        </div>
      </div>}</>}</div></>
  );
}

export default App;
